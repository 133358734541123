.content {
  width: 100%;
  background-color: var(--blue-16);
  padding-left: 6.25rem;
  padding-right: 2.4375rem;
}

.container {
  display: flex;
  width: 100%;
  color: var(--white);
  padding: 2.5rem 0;
  margin: 0 auto;
  max-width: 107.5rem;
  border-top: 1px solid var(--footer-border);

  p {
    margin: 0;
    font-size: 0.8125rem;
    line-height: 1.3;
    letter-spacing: -0.03em;
  }
}

.withLogo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 25.375rem;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    border-right: 1px solid var(--white-t2);
  }

  .copyright {
    margin-top: 21.5rem;
  }
}

.footerLogo {
  width: 6.25rem;
  height: 3.3125rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -2rem;
    left: 0;
    height: 1px;
    width: 4rem;
    border-bottom: 1px solid var(--white-t2);
  }
}

.social {
  display: flex;
  flex-direction: column;
  gap: 0.6875rem;
  margin-top: 3.625rem;
  max-width: 13.125rem;
  width: 100%;
}

.soc {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigations {
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.links {
  height: 100%;
  display: flex;
  gap: 3.375rem;
  margin-top: -0.375rem;
}

.columnOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 10.8125rem;
}

.yandex {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-bottom: -0.5rem;

  svg {
    width: 10.6875rem;
    height: 100%;
  }

  a {
    height: 1.5rem;
  }
}

.nav {
  max-width: 9.4375rem;
  width: 100%;
  margin: -0.1875rem 0 0;

  a span {
    font-weight: 500;
    font-size: 1.625rem;
    line-height: 1.31;
    letter-spacing: -0.03em;
  }
}

.columnTwo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 10.8125rem;
}

.opsguru {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-bottom: -0.5rem;

  p {
    margin: 0;
  }
}

.navTwo {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  a {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.31;
    letter-spacing: -0.03em;
    color: var(--white);
  }
}

.report {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  .captcha {
    margin-top: 1.875rem;
    font-weight: 300;
    font-size: 0.5rem;
    line-height: 1.54;
    color: var(--grey-a);
  }
}

.skamSvg {
  max-height: 1.125rem;
}

.skamSvg {
  max-height: 1.125rem;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 12.9375rem;
  width: 100%;
}

.button {
  border: 1px solid var(--white);
  color: var(--white);
  padding: 0.6875rem 1rem;
  max-width: 12.9375rem;
  width: 100%;
  text-align: center;
  background: none;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -0.01em;
  cursor: pointer;
}

.link {
  font-weight: 500;
  color: var(--grey-f2);
}

.navThree {
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 1.54;
  color: var(--grey-f2);
  width: 100%;
  margin-top: 1.75rem;
  position: relative;

  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    position: absolute;
    top: -0.875rem;
    left: 0;
    height: 1px;
    width: 4rem;
    border-top: 1px solid var(--white-t2);
  }

  a {
    color: var(--grey-f2);
  }
}

.iconColor svg path {
  fill: var(--white);
}

.mobile {
  display: none;
}

.downloadLink {
  position: relative;

  span {
    position: absolute;
    right: -4.375rem;
    width: 100%;
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: 400;
  }
}

.themeSwitcher {
  margin-top: 0.875rem;
}

@media (max-width: 75rem) {
  .withLogo {
    max-width: 15rem;
  }
}

@media (max-width: 62rem) {
  .content {
    padding: 0;
  }

  .container {
    flex-direction: column;
    padding: 1rem 1rem 5.375rem;
    margin: 0;
  }

  .withLogo {
    max-width: 100%;
    padding-bottom: 2.8125rem;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-right: none;
      border-bottom: 1px solid var(--white);
    }
  }

  .copyright {
    display: none;
  }

  .navigations {
    margin: 2.8125rem 0 0;
    padding-bottom: 2.8125rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid var(--white);
    }
  }

  .columnOne {
    .yandex {
      display: none;
    }
  }

  .columnTwo {
    .opsguru {
      display: none;
    }

    .navTwo {
      gap: 1.25rem;
      margin-top: 0.1875rem;
    }
  }

  .report {
    display: none;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.33;
    letter-spacing: -0.02em;

    .soc {
      display: flex;
      justify-content: space-between;
      list-style: none;
      margin: 3.125rem 0 0;
      padding: 0;

      svg {
        width: 3.125rem;
        height: 3.125rem;
      }
    }
  }

  .advertisingTwo {
    display: flex;
    justify-content: space-between;
    margin-top: 2.8125rem;
    align-items: flex-end;
  }

  .yandex--mobile {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;

    svg {
      width: 7.5rem;
      height: 100%;
    }

    a {
      height: 1rem;
    }
  }

  .advertising {
    display: flex;
    justify-content: space-between;
    margin-top: 3.125rem;

    p span {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.33;
      letter-spacing: -0.02em;
    }

    .copyright {
      max-width: 8.5rem;
      text-align: end;
      display: flex;
      flex-direction: column;
    }
  }

  .button {
    border: 1px solid var(--white);
    color: var(--white);
    min-width: 100%;
    -webkit-text-fill-color: var(--white);
    padding: 1rem 0;
    text-align: center;
    background: none;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: -0.01em;
    cursor: pointer;
    margin-top: 0.8125rem;
  }

  .themeSwitcher {
    margin-top: 0.9375rem;

    & > button {
      justify-content: center;
      column-gap: 0.5rem;
    }
  }
}

@media (max-width: 48rem) {
  .withLogo {
    font-size: 0.75rem;
    line-height: 1.33;
    letter-spacing: -0.02em;
    padding-bottom: 2.4375rem;
  }

  .social p {
    font-size: 0.75rem;
    line-height: 1.33;
    letter-spacing: -0.02em;
  }

  .navigations .links {
    gap: 6.5rem;
  }

  .columnOne {
    .nav {
      margin: -0.1875rem 0 0;
      max-width: 9.4375rem;
      width: 100%;

      a span {
        font-size: 1.25rem;
        line-height: 1.4;
      }
    }

    .yandex--mobile {
      display: flex;
      flex-direction: column;
      gap: 1.875rem;
      margin-bottom: -0.5rem;

      svg {
        width: 10.6875rem;
        height: 100%;
      }

      a {
        height: 1.5rem;
      }
    }
  }

  .columnTwo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 10.8125rem;

    .navTwo {
      display: flex;
      flex-direction: column;
      gap: 1.375rem;

      a {
        font-size: 0.875rem;
        line-height: 1.29;
      }
    }
  }

  .downloadLink span {
    right: -3.75rem;
    font-size: 0.5rem;
  }
}
