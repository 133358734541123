.container {
  position: relative;
  width: 100%;
}

.content {
  position: absolute;
  z-index: 1;
  top: 4rem;
  width: 100%;
}

.openButton {
  width: 100%;
  height: 3.125rem;
  padding: 0.75rem 1.25rem 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--blue-16);
  color: var(--white);
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--white);
  cursor: pointer;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid var(--grey-45);
}

.item {
  width: 100%;
  height: 3.75rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--grey-f3);
  line-height: 1.31;
  font-weight: 500;
  border: none;
  background-color: var(--blue-16);
  cursor: pointer;

  &--active {
    background-color: var(--grey-32);
  }
}
